import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOutUser } from "../../config/authentication";
import "../../assets/styles/Navigation.css";
import image1 from "../../assets/images/LOGO.png";
/**
 * The class declaration for the navbar
 */
const Navigation = ({ photoURL, displayName, selectView }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [view, setView] = useState(localStorage.getItem("selectedView") || "page");
  const navigate = useNavigate();

  const handleLogout = () => {
    signOutUser();
    setDropdownVisible(false);
    localStorage.setItem("selectedView", "page");
    navigate("/");
  }

  const handleArrowClick = () => {
    setDropdownVisible(!dropdownVisible);
  }

  const handleViewChange = (event) => {
    const selected = event.target.value;
    setView(selected);
    localStorage.setItem("selectedView", selected);
    selectView(selected);
  }

  const handlehelp = () => {
    navigate("/contact");
  }

  return (
    <>
      <div className="dash-navbar">
        <div className="dash-img-div">
          <a href="/">
            <img className="d360-logo" src={image1} alt="Logo"></img>
          </a>
          <span class="pathSymbol">&gt;</span>
          <a href="/photography" style={{textDecoration: 'None'}}>
            <span style={{ color: "#4183c4", fontSize: "20px", marginLeft: "10px" }}>Dashboard</span>
          </a>
          <span class="pathSymbol">&gt;</span>
          <select className="dash-select" id="dash-select" name="dash-select" onChange={handleViewChange} value={view}>
            <option value="page"><b>Page View</b></option>
            <option value="lot"><b>Lot/Date View</b></option>
          </select>
        </div>
        <div className="dash-userInfo">
          <img src={photoURL} className="dash-profile" alt="pic" />
          <span className="dash-login-name">
            {displayName}
          </span>
          <span onClick={handleArrowClick} className="dropdown-arrow">{dropdownVisible ? "▲" : "▼"}</span>
        </div>
        {dropdownVisible && (
          <div className="dash-dropdown-content">
            <button className="dash-logout-button" onClick={handleLogout}>
              Logout
            </button>
            <button className="dash-logout-button" onClick={handlehelp}>
              Feedback
            </button>
          </div>
        )}
      </div>

    </>
  )
}



export default Navigation;
