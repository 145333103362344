import React, { useState, useEffect } from "react";
import "../../assets/styles/dashboard.css";
import logo from "../../assets/images/google.png";
import { signIn } from "../../config/authentication";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";

const Login = () => {
    const [isSignedIn, setIsSignedIn] = useState(null);

    useEffect(() => {
        // Inbuilt firebase functions to handle user authorization and authentication
        function initFirebaseAuth() {
            onAuthStateChanged(getAuth(), authStateObserver);
        }
        // Handling whether the user is signed in or not
        function authStateObserver(user) {
            if (user) {
                // If signed in and initial value of issignedin is false or null, then set it to true
                if (isSignedIn === false || isSignedIn === null) {
                    setIsSignedIn(true);
                }
            } else {
                // if signed in is true, and logout action is triggered, then seet it to false
                if (isSignedIn === true || isSignedIn === null) {
                    setIsSignedIn(false);
                }
            }
        }
        initFirebaseAuth();
        // Execute the use effect function only when the value of issigned in changes
    }, [isSignedIn]);

    return (
        <>
            {!isSignedIn ? (
                <div role="button" className="login-div" onClick={signIn}>
                    <div>
                        <img className="login-logo" src={logo} alt="" />
                    </div>
                    <p className="login-text">Sign In With Google</p>
                </div>
            ) : (
                <Navigate to="/dashboard/page" />
            )}
        </>
    )
}

export default Login;