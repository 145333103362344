import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Divider from '../divider';
import Header from '../Navbar';
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Footer from '../Footer';
import SocialMedia from '../Social-media';

const Anlytics = () => {
    const image1 = require("../../assets/images/Analytics.png");
    const image2 = require("../../assets/images/Analytics2.png");
    const [json, setJson] = useState([]);
    const token = useRef(process.env.ACCESS_TOKEN);
    const cloudURL = "https://us-central1-d360-studio-dev.cloudfunctions.net/send_json";
    const payload = useRef({
        "url": "https://storage.googleapis.com/d360us/website/analytics_data.json"
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(cloudURL, {params: payload.current}, {
                    headers: {
                        'Authorization': `Bearer ${token.current}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    crossDomains: true,
                    timeout: 100000
                });
                setJson(response["data"]["output"]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
        Aos.init({
            duration: 200,
          });
    }, []);

    return (
        <>
            <Divider />
            <Header />
            <div className='container'>
                <h1 className='my-4 about'>Industry Stock Analysis using AI/Big Data & Tech Consulting</h1>
                <button type="button" className="btn btn-md btn-outline-primary"><Link to='/' style={{ color: "black", textDecoration: "none" }}>Back</Link></button>
                {json && json.map((item, index) => {
                    return (
                        <div className="privacy-flex-container" key={index}>
                            <div className='privacy-flex-container-left col-lg-6'>
                                <h2>{item.title}</h2>
                            </div>
                            <div className='privacy-flex-container-right col-lg-6'>
                                <ul style={{ marginBottom: "0px" }}>
                                    {item.para.split('\n').map((para, index) => (<li key={index}>{para}</li>))}
                                </ul>
                                {item.id === 2 && (
                                    <ul>
                                        <li>Get details here: <a target="_blank" rel="noreferrer" href="https://docs.google.com/presentation/d/1YKjaMB2znOpKzyj-SimYvXOru3A8G2Ee1TKF-Tza_0E/edit?pli=1#slide=id.gf3148104a2_0_17">Link</a></li>
                                    </ul>
                                )}
                                <img src={item.id === 1 ? image2 : image1} className='img-fluid' alt='' />
                            </div>
                        </div>
                    )
                })}
                <div style={{ marginTop: "50px" }}><SocialMedia /></div>
                <Footer />
            </div>
        </>
    )
}

export default Anlytics

// Link for the second data card:
// https://docs.google.com/presentation/d/1YKjaMB2znOpKzyj-SimYvXOru3A8G2Ee1TKF-Tza_0E/edit#slide=id.gf3148104a2_0_17