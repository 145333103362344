import React, { useEffect, useState, useRef } from "react";
import Divider from "../divider";
import Header from "../Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import SocialMedia from "../Social-media";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const Announcement = () => {
  const [json, setJson] = useState([]);
  const token = useRef(process.env.ACCESS_TOKEN);
  const cloudURL = "https://us-central1-d360-studio-dev.cloudfunctions.net/send_json";
  const payload = useRef({
    "url": "https://storage.googleapis.com/d360us/website/announcements.json"
  });

  // AOS library is used for animation
  // This file is contains the structure and content of announcement page
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(cloudURL, { params: payload.current }, {
          headers: {
            'Authorization': `Bearer ${token.current}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          crossDomains: true,
          timeout: 100000
        });
        setJson([response["data"]["output"]]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    Aos.init({
      duration: 200,
    });
  }, []);

  return (
    <>
      <Divider />
      <Header />
      <div className="container">
        <h1 className="my-4 about">Announcement</h1>
        <button type="button" className="btn btn-md btn-outline-primary">
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            Back
          </Link>
        </button>
        <div className="flex-container row rev">
          {json && json.map((item, index) => {
            return (
              <div key={index} className={item.index % 2 === 0 ? "flex-item-left col-lg-6 item1" : "flex-item-right col-lg-6 item1"} data-aos="fade-up" data-aos-duration="2000">
                <h2>{item.title}</h2>
                <h6 className="card-subtitle mb-2 text-muted">{item.date}</h6>
                <div>
                  {item.content.split('\n').map((para, index) => (
                    <p>{para}</p>
                  ))}
                </div>
              </div>
            )
          })}
          <div className="flex-item-right col-lg-6 img">
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <SocialMedia />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Announcement;