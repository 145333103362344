import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import axios from "axios";
import "aos/dist/aos.css";
import "../../assets/styles/contact.css";
import Divider from "../divider";
import Header from '../Navbar';
import Footer from "../Footer";
import SocialMedia from "../Social-media";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [done, setDone] = useState(false);
  const [json, setJson] = useState([]);
  const token = useRef(process.env.ACCESS_TOKEN);
  const cloudURL = "https://us-central1-d360-studio-dev.cloudfunctions.net/send_json";
  const payload = useRef({
    "url": "https://storage.googleapis.com/d360us/website/hours.json"
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const html = `
    <html>
      <head>
        <style>
          /* Add your custom CSS styles here if needed */
        </style>
      </head>
      <body>
        <p>Message Details:</p>
        <p><strong>Name: </strong>${name}</p>
        <p><strong>Email Id: </strong>${email}</p>
        <p><strong>Message: </strong>${msg}</p>
        <button>Take Action</button>
      </body>
    </html>
  `;
    try {
      await sendEmail(html, "Contact Form Submission", "sales@d360.tech");
    } catch (error) {
      console.error("Error sending mail", error);
    }
  };

  const sendEmail = async (html, subject, mail) => {
    const payload = {
      html: html,
      subject: subject,
      email: mail
    };

    try {
      axios.defaults.withCredentials = false
      axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.post("https://us-central1-d360-assist-dev.cloudfunctions.net/sendEmail", {
        data: payload
      }, {
        crossDomain: true,
        timeout: 20000
      })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.error("Error sending email", error);
    }

    let name = document.getElementById("name");
    let email = document.getElementById("email");
    let message = document.getElementById("message");
    name.value = "";
    email.value = "";
    message.value = "";
    setDone(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(cloudURL, { params: payload.current }, {
          headers: {
            'Authorization': `Bearer ${token.current}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          crossDomains: true,
          timeout: 100000
        });
        setJson(response["data"]["output"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    Aos.init({
      duration: 200,
    });
  }, []);

  return (
    <div>
      <Divider />
      <Header />
      <div className="container">
        <h1 className="my-4 about">Reach Out To Us</h1>
        <button type="button" className="btn btn-md btn-outline-primary">
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            Back
          </Link>
        </button>
      </div>
      <div
        className="flexi-container"
        id="contact"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className='maps flexi-item-right data-aos="zoom-in"  data-aos-duration="1500"' style={{ marginTop: "30px" }}>
          <h5>
            <i className="fa-solid fa-location-dot"></i> 580 5th Avenue, Suite
            421,New York, NY 10036
          </h5>
          <iframe
            title="address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.2239036950923!2d-73.98134058459365!3d40.75709987932697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259cd0abb0595%3A0x4b4bd71f97a39e4c!2sV360%20Studio!5e0!3m2!1sen!2sin!4v1651672423637!5m2!1sen!2sin!"
            width="300"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>


        <div className='maps flexi-item-right data-aos="zoom-in"  data-aos-duration="1500"' style={{ marginTop: "30px" }}>
          <h5>
            <i className="fa-solid fa-location-dot"></i> 6RCM+GJW NIRBHAY GEMS, Katargam, Surat, Gujarat 395008
          </h5>
          <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7438.756309665305!2d72.83252222417781!3d21.216848093586872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s6RCM%2BGJW%20NIRBHAY%20GEMS%2C%20Katargam%2C%20Surat%2C%20Gujarat%20395008!5e0!3m2!1sen!2sin!4v1679500961774!5m2!1sen!2sin"
            title="Gujarat Address"
            width="300"
            height="400"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

      </div>

      <div
        className="flexi-container"
        id="contact"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="maps">
          <div className="contact-form-wrapper d-flex justify-content-center">
            <form
              className="contact-form"
              onSubmit={handleSubmit}
            >
              <h1 className="title">Contact us</h1>
              <p className="description text-center">
                Feel free to contact us if you need any assistance.
                <br />
                Ph: +1 646-598-7690
                <br />
                email: sales@d360.tech
              </p>
              <div>
                <input
                  type="text"
                  className="form-control rounded border-white mb-3 form-input"
                  id="name"
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Name"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  onChange={(event) => setEmail(event.target.value)}
                  className="form-control rounded border-white mb-3 form-input"
                  placeholder="Email"
                  required
                />
              </div>
              <div>
                <textarea
                  id="message"
                  onChange={(event) => setMsg(event.target.value)}
                  className="form-control rounded border-white mb-3 form-text-area"
                  rows="5"
                  cols="30"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="submit-button-wrapper">
                <input type="submit" value="Send" />
                {!done ? <></> : <div className="success">Thank you for submitting!</div>}
              </div>
            </form>
          </div>
        </div>
        <div className="maps bus-hrs-main">
          <div className="bus-hrs-wrapper">
            <p>Business Hours</p>
            {json && json.map((item, index) => {
              return (
                <div className="days" key={index}>
                  <div className="day">{item.Day}</div>
                  <div className="timing">{item.Time}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <SocialMedia />
      <Footer />
    </div>

  );
};


export default Contact;