import React, { useState, useEffect, useRef } from 'react'
import '../../assets/styles/photography.css';
import Divider from '../divider'
import Header from '../Navbar';
import { Link } from "react-router-dom";
import Footer from '../Footer';
import SocialMedia from '../Social-media';
import Login from '../Dashboard/Login';
import axios from 'axios';


const Photography = () => {
    const [json, setJson] = useState([]);
    const token = useRef(process.env.ACCESS_TOKEN);
    const cloudURL = "https://us-central1-v360studio-e1956.cloudfunctions.net/send_json";
    const payload = useRef({
        "url": "https://storage.googleapis.com/d360us/website/photography_data.json"
    });

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.post(cloudURL, { params: payload.current }, {
                    headers: {
                        'Authorization': `Bearer ${token.current}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    crossDomains: true,
                    timeout: 100000
                });
                setJson(response["data"]["output"]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Divider />
            <Header />
            <div className='container'>
                <h1 className='my-4 about'>D360° Photography Service</h1>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <button type="button" className="btn btn-md btn-outline-primary back-btn" ><Link to='/' style={{ color: "black", textDecoration: "none" }}>Back</Link></button>
                    <Login />
                </div>
                <div className="privacy-flex-container" >
                    <div className='privacy-flex-container-left col-lg-6'>
                        <h2>Outsource/Simplify <span className='blue-text'>Photography</span> and Technology to Us!</h2>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6'>
                        <ul>
                            <li>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li>Same Day Service for upto 700 diamond</li>
                            <li>Instant D360° loading with US based Google Cloud Platform.</li>
                            <li>Ring, Pendent, Earring, Pendent photography</li>
                            <li>Malca-Amit and Brinks window pickup/drop-off </li>
                            <li>Work with all major courier Fedex, UPS, USPS and Ferrari </li>
                            <li>Quality Photography for Blue Nile, Brilliant Earth, Rapnet, VDB, Clean Origin, Green Rock, Fire Diamonds, etc. </li>
                            <li>250k stones handled since 2018 worth more than $1B.</li>
                        </ul>
                    </div>
                </div>

                {json && json.map((item, index) => {
                    return (
                        <div className="privacy-flex-container frame-card" key={index}>
                            <div className='privacy-flex-container-left col-lg-6 justify-content-center'>
                                <iframe title='anish' src={item.src} className="responsive-iframe frame" scrolling="no" id="displayStone" frameBorder="0">
                                </iframe>
                            </div>
                            <div className='privacy-flex-container-right col-lg-6 frame-content'>
                                <ul>
                                    {item.para.split('\n').map((para, index) => (
                                        <li key={index}>{para}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )
                })}
                <div style={{ marginTop: "50px" }}><SocialMedia /></div>
                <Footer />
            </div >
        </>
    )
}

export default Photography;