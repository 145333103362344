import React, { useState, useEffect, useRef } from 'react'
import Divider from '../divider'
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Footer from '../Footer';
import SocialMedia from '../Social-media';
import Header from '../Navbar';
import axios from 'axios';

const Logistics = () => {
  const [json, setJson] = useState([]);
  const token = useRef(process.env.ACCESS_TOKEN);
  const cloudURL = "https://us-central1-d360-studio-dev.cloudfunctions.net/send_json";
  
  const payload = useRef({
    "url": "https://storage.googleapis.com/d360us/website/logistics_data.json"
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(cloudURL, { params: payload.current }, {
          headers: {
            'Authorization': `Bearer ${token.current}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          crossDomains: true,
          timeout: 100000
        });
        setJson(response["data"]["output"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    Aos.init({
      duration: 200,
    });
  }, []);

  return (
    <>
      <Divider />
      <Header />
      <div className='container'>
        <h1 className='my-4 about'>Diamond Logistical Handling</h1>
        <button type="button" className="btn btn-md btn-outline-primary"><Link to='/' style={{ color: "black", textDecoration: "none" }}>Back</Link></button>
        {json && json.map((item, index) => {
          return (
            <div className="privacy-flex-container" key={index}>
              <div className='privacy-flex-container-left col-lg-6'>
                <h2>{item.title}</h2>
              </div>
              <div className='privacy-flex-container-right col-lg-6'>
                <p>
                  <ul>
                    {item.para.split('\n').map((para, index) => (<li key={index}>{para}</li>))}
                  </ul>
                </p>
              </div>
            </div>
          )
        })}
        <div style={{ marginTop: "50px" }}><SocialMedia /></div>
        <Footer />
      </div>
    </>
  )
}

export default Logistics;
