import React from "react";
import "../../assets/styles/dashboard.css";

const Register = ({ flag }) => {
    return (
        <div className="message">
            {flag === 0 ?
                <>
                    To register, contact <span style={{ color: "blue" }}>kalpesh@d360.tech</span>
                    <br></br>
                    If regular user, continue with below Sign-In Option
                </> :
                <>
                    You have not registered yourself.<br></br>
                    To register, contact <span style={{ color: "blue" }}>kalpesh@d360.tech</span>
                </>
            }
        </div>
    )
};

export default Register;